<template>
  <div>
    <section v-if="!loading">
      <v-sheet color="white" class="pa-10 rounded20">
        <h2 class="overline">Filtrar movimientos</h2>
        <v-text-field
          label="Codigo de receta"
          prepend-icon="mdi-barcode"
          v-model="recipe"
          clearable
        />
          <v-text-field
          label="Farmacia"
          prepend-icon="mdi-hospital"
          v-model="pharmacy"
          clearable
        />
        <v-select
          prepend-icon="mdi-traffic-light"
          label="Estado"
          :items="statusOptions"
          v-model="status"
        />
        <v-spacer></v-spacer>
        <v-row justify="end">
          <v-btn text @click="$emit('clean'), status = null">
            Limpiar filtros
          </v-btn>
          <v-btn
            color="teal"
            rounded
            dark
            outlined
            @click="onFilter(), (datePickerMenu = false)"
          >
            Filtrar
          </v-btn>
        </v-row>
      </v-sheet>
    </section>
    <v-skeleton-loader v-else type="card" />
  </div>
</template>
<script>
import { receptionMovementTypes } from "../../services/receptionMovementsUtils";

export default {
  name: "RecipeFilter",

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },

  data: () => ({
    recipe: null,
    reception: null,
    pharmacy: null,
    status: null,
    statusOptions: receptionMovementTypes,
  }),

  methods: {
    onFilter() {
      const status =  this.statusOptions.indexOf(this.status)
      this.$emit("filter", {
        recipe: this.recipe,
        pharmacy: this.pharmacy,
        status: status === -1 ? null : status,
      });
    },
  },
};
</script>
