
import { differenceInMinutes, differenceInHours, differenceInDays, format } from "date-fns";

export function formatTimeAgo(date) {

    const at = new Date(date);

    const diff = differenceInMinutes(new Date(), at)

    if (diff < 60) {
        return `${diff}m`
    } else if (diff >= 60 && diff < 1440) {
        return `${Math.floor(diff / 60)} horas`
    } else return `${Math.floor(Math.floor(diff / 60) / 24)} días`
}

export function formatDate(date) {
    return format(new Date(date), 'dd/MM/yyyy hh:mm a')
}
