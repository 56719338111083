<template>
  <div>
    <v-row class="justify-center">
      <div class="i-overline">RECEPCIONES</div>
    </v-row>
    <v-row class="justify-center mx-xl-16">
      <v-col v-for="(card, index) in cards" :key="index" cols="12" sm="4">
        <ReceptionDataCard :info="getCardData(card)" :loading="loading" />
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" md="4">
        <v-select
          background-color="white"
          color="teal"
          rounded
          flat
          x-large
          :items="timeOptions"
          v-model="time"
          @change="getReceptionsInfo({ time })"
        >
          <template v-slot:selection="{ item }">
            <span
              class="d-flex justify-center overline"
              style="width: 100%; color: teal; font-weight:bold"
            >
              {{ item.text }}
            </span>
          </template>
        </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-row>
        <v-col sm="12" md="12" xl="3">
          <ReceptionFilter
            @filter="getReceptions($event), filters = $event"
            @clean="getReceptions(), filters = null"
            :loading="loading"
          />
        </v-col>
        <v-col sm="12" md="12" xl="9">
          <ReceptionTable
            :properties="properties"
            :items="receptions"
            :currentPage="currentPage"
            :total="count"
            :loading="loading"
            @pagination="pageChange($event)"
          />
        </v-col>
      </v-row>
    </v-row>
  </div>
</template>

<script>
import ReceptionTable from "../../components/reception/ReceptionTable.vue";
import ReceptionFilter from "../../components/reception/ReceptionFilter.vue";
import ReceptionDataCard from "../../components/reception/ReceptionDataCard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    ReceptionTable,
    ReceptionFilter,
    ReceptionDataCard,
  },
  data: () => ({
    cards: [
      {
        key: "iosperIncomes",
        name: "Novedades desde Iosper",
        color: "teal",
      },
      {
        key: "receptionsProcessed",
        name: "Novedades procesadas",
        color: "blue",
      },
      {
        key: "receptionsErrors",
        name: "Errores",
        color: "red",
      },
      {
        key: "newRecipes",
        name: "Recetas nuevas",
        color: "amber",
      },
      {
        key: "pharmacyAssignments",
        name: "Asignaciones de farmacia",
        color: "purple",
      },
    ],
    properties: [
      {
        key: "message",
        name: "Mensaje",
      },
      {
        key: "status",
        name: "Tipo de movimiento",
      },
      {
        key: "date",
        name: "Fecha",
      },
    ],
    currentPage: 1,
    timeOptions: [
      {
        text: "En la última hora",
        value: 1,
      },
      {
        text: "En las últimas 24 horas",
        value: 24,
      },
      {
        text: "En las últimas 72 horas",
        value: 72,
      },
      {
        text: "En la última semana",
        value: 168,
      },
      {
        text: "En el último mes",
        value: 720,
      },
      {
        text: "Desde el comienzo",
        value: 0,
      },
    ],
    time: {
      text: "Última hora",
      value: 1,
    },
    filters: null
  }),
  mounted() {
    this.getReceptions();
    this.getReceptionsInfo();
  },

  computed: {
    ...mapGetters("receptions", [
      "receptions",
      "iosperIncomes",
      "receptionsProcessed",
      "receptionsErrors",
      "newRecipes",
      "pharmacyAssignments",
      "count",
      "loading",
    ]),
  },

  methods: {
    
    ...mapActions("receptions", ["getReceptions", "getReceptionsInfo"]),

    getCardData(card) {
      return {
        value: this[card.key],
        ...card,
      };
    },
    pageChange(page) {
      this.currentPage = page;
      this.getReceptions({ page: this.currentPage, ...this.filters });
    },
  },
};
</script>

<style lang="scss" scoped>
.i-overline {
  font-family: "Roboto", sans-serif !important;
  letter-spacing: 0.15em;
  font-size: 2.215rem;
}
</style>