
export const receptionMovementTypes = [
    'Ingreso IOSPER',
    'Inicio de procesamiento',
    'Creación de médico',
    'Creación de farmacia',
    'Creación de afiliado',
    'Creación de receta',
    'Anulación inválida',
    'Anulación de receta' ,
    'Modificación de receta',
    'Asignación de receta',
    'Ingreso duplicado',
    'Renglon entregado',
    'Error'
]

export function getMovementTypeName(index) {
    return receptionMovementTypes[index]
}
