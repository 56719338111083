<template>
  <v-sheet class="pa-10 rounded20">
    <div class="overline">
      movimientos de recepción
    </div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th
              v-for="property in properties"
              :key="property.key"
              :class="property.class"
            >
              {{ property.name }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.name">
            <td>{{item.message}}</td>
            <td>{{getMovementTypeName(item.status)}}</td>
            <td>{{parseDate(item.date)}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-pagination
      :value="currentPage"
      :length="Math.ceil(total / 15)"
      color="teal darken-1"
      circle
      :disabled="loading || !items.length"
      total-visible="5"
      @input="$emit('pagination', $event)"
    ></v-pagination>
  </v-sheet>
</template>

<script>
import { getMovementTypeName } from '../../services/receptionMovementsUtils'
import { formatDate } from '../../services/dateUtils'
import { format } from 'date-fns'
export default {
  props: {
    properties: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    currentPage: {
      type: Number,
      required: false,
      default: 1,
    },
  },

  methods: {
    getMovementTypeName(i) {
      return getMovementTypeName(i)
    },
    parseDate(date) {
      return `${formatDate(date)}`
    }
  }
};
</script>
<style>
</style>
