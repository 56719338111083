<template>
  <v-sheet class="i-card text-center">
    <div class="text-h3" v-if="!loading">
      {{ info.value }}
    </div>
    <v-progress-circular
      v-else
      class="ma-2"
      indeterminate
      :color="info.color"
    />
    <div class="overline">
      {{ info.name }}
    </div>
    <div class="text-center">
      <v-dialog v-model="dialog" width="500">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            :color="info.color"
            v-bind="attrs"
            v-on="on"
            :loading="loading"
            outlined
            rounded
            dark
          >
            DETALLES
          </v-btn>
        </template>
        <v-card class="pa-5 rounded20">
          <div class="text-h3 text-center" v-if="!loading">
            {{ info.value }}
          </div>
          <v-card-title class="overline justify-center">
            {{ info.name }}
          </v-card-title>
          <v-card-text>
          </v-card-text>
          <v-card-actions class="ma-1 mb-0">
            <v-btn
              outlined
              rounded
              block
              :color="info.color"
              @click="dialog = false"
            >
              Ocultar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-sheet>
</template>
<script>
export default {
  props: {
    info: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
  }),
};
</script>

<style>
.i-card {
  border-radius: 20px;
  padding: 15px;
}
</style>